<template>
  <a-modal width="700px" v-model="visible" title="修改头像" @cancel="cancel" @ok="handleOk">
    <div>
      <g-title>头像/挂件</g-title>
      <div class="top">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeCroUpload"
          @change="handleUpChange"
        >
          <div v-if="imgUrl" style="position: relative">
            <div
              class="select-pendant"
              :style="`top:${selectName.top}px;left:${selectName.left}px;transform: rotate(${selectName.deg}deg);`"
            >
              <img v-if="selectName" :src="selectName.url" alt="" />
            </div>
            <div class="img-div">
              <img ref="showImg" :src="imgUrl" alt="avatar" class="upload_img" />
              <a-icon :style="'color:' + color" class="change-img" type="plus" />
            </div>
          </div>
          <div v-else style="position: relative">
            <div
              class="select-pendant"
              :style="`top:${selectName.top}px;left:${selectName.left}px;transform: rotate(${selectName.deg}deg);`"
            >
              <img v-if="selectName" :src="selectName.url" alt="" />
            </div>
            <div class="img-div">
              <img ref="showImg" src="~@/assets/tx.png" alt="avatar" class="upload_img" />
              <a-icon :style="'color:' + color" class="change-img" type="plus" />
            </div>
          </div>
        </a-upload>
      </div>
    </div>
    <div style="margin-top: 10px">
      <g-title>挂件列表</g-title>
      <div class="pendant-list">
        <div
          class="pendant"
          :style="item.url == selectName.url ? 'border:1px solid' + color : ''"
          @click="selectPendant(item)"
          v-for="item in pendantList"
          :key="item.id"
        >
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
    <user-cropper-model ref="userCropperModel" @upImg="upImg"> </user-cropper-model>
  </a-modal>
</template>

<script>
import Vue from 'vue'
import { putAction,getAction  } from '@/api/manage'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import userCropperModel from './../cropper-model.vue'
import axios from 'axios'
export default {
  mixins: [UploadFileToOssMixins],
  components: {
    userCropperModel
  },
  props: {
    userInfoWeacher: {
      type: Object,
      default: () => ({
        userFile: {}
      })
    },
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      imgUrl: '',
      avatarLoading: false,
      fileList: [],
      pendantList: [],
      selectName: {},
      formData: ''
    }
  },
  methods: {
    handleUpChange({ file, fileList }) {
      return false
    },
    handleChangeUpload(info, type) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.imgUrl = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    },
    async getPendantList() {
      const res = await getAction('/headwear/page')
      if (res.code == 200) {
        this.pendantList = res.data.records
      } else {
        this.$message.error(res.msg)
      }
    },
    open(pendant) {
      this.visible = true
      this.getPendantList()
      this.selectName = pendant || ''
      this.imgUrl = this.userInfoWeacher.userFile.avatar || ''
      const userInfo = Vue.ls.get(USER_INFO)
      let { id, userFile } = userInfo
      if (!userFile.workDays) userFile.workDays = []
      this.formData = {
        ...this.formData,
        id,
        userFile
      }
    },
    handleOk() {
      this.visible = false
      this.$emit('editUserImg', { imgUrl: this.imgUrl, selectName: this.selectName })
      this.imgList = []
    },
    cancel() {
      this.visible = false
    },
    beforeCroUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.openCropper(url)
      return false
    },
    selectPendant(item) {
      this.selectName = item
    },
    openCropper(url) {
      this.$refs.userCropperModel.openModal(url)
    },
    handleChangeUpload(info, index) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.userFile.avatar = params.filePath
        this.imgUrl = params.filePath
        this.removeOssFilePolicy(info.file)
        putAction('/sys/user/edit_user_file', this.formData).then(res => {
          this.avatarLoading = false
          if (res.success) {
            this.$message.success(res.message)
            Vue.ls.set(USER_INFO, this.formData, 7 * 24 * 60 * 60 * 1000)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    async upImg(file) {
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: center;
  align-items: end;
  min-height: 120px;
}
/deep/ .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  border: none !important;
  background-color: #fff;
}
/deep/ .ant-upload.ant-upload-select-picture-card:hover {
  border: 1px solid var(--theme-color) !important;
}
/deep/ .ant-upload-picture-card-wrapper {
  width: 104px !important;
}
.change-img {
  font-size: 30px;
  text-align: center;
  line-height: 95px;
}
.img-div {
  width: 95px;
  height: 95px;
  border-radius: 50% !important;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
  img {
    width: 95px;
    height: auto;
    left: 0;
    position: absolute;
    &:hover {
      opacity: 0.3;
    }
  }
}
.select-pendant {
  position: absolute;
  top: -14px;
  width: 35px;
  height: 35px;
  left: 30px;
  text-align: center;
  z-index: 1;
  img {
    width: 80%;
    height: auto;
  }
}
.pendant-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  max-height: 300px;
  overflow-y: auto;
  .pendant {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    img {
      width: 80%;
      height: auto;
    }
  }
}
</style>
