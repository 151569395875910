var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "700px", title: "修改头像" },
      on: { cancel: _vm.cancel, ok: _vm.handleOk },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        [
          _c("g-title", [_vm._v("头像/挂件")]),
          _c(
            "div",
            { staticClass: "top" },
            [
              _c(
                "a-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    name: "avatar",
                    "list-type": "picture-card",
                    "show-upload-list": false,
                    "before-upload": _vm.beforeCroUpload,
                  },
                  on: { change: _vm.handleUpChange },
                },
                [
                  _vm.imgUrl
                    ? _c("div", { staticStyle: { position: "relative" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "select-pendant",
                            style: `top:${_vm.selectName.top}px;left:${_vm.selectName.left}px;transform: rotate(${_vm.selectName.deg}deg);`,
                          },
                          [
                            _vm.selectName
                              ? _c("img", {
                                  attrs: { src: _vm.selectName.url, alt: "" },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "img-div" },
                          [
                            _c("img", {
                              ref: "showImg",
                              staticClass: "upload_img",
                              attrs: { src: _vm.imgUrl, alt: "avatar" },
                            }),
                            _c("a-icon", {
                              staticClass: "change-img",
                              style: "color:" + _vm.color,
                              attrs: { type: "plus" },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", { staticStyle: { position: "relative" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "select-pendant",
                            style: `top:${_vm.selectName.top}px;left:${_vm.selectName.left}px;transform: rotate(${_vm.selectName.deg}deg);`,
                          },
                          [
                            _vm.selectName
                              ? _c("img", {
                                  attrs: { src: _vm.selectName.url, alt: "" },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "img-div" },
                          [
                            _c("img", {
                              ref: "showImg",
                              staticClass: "upload_img",
                              attrs: {
                                src: require("@/assets/tx.png"),
                                alt: "avatar",
                              },
                            }),
                            _c("a-icon", {
                              staticClass: "change-img",
                              style: "color:" + _vm.color,
                              attrs: { type: "plus" },
                            }),
                          ],
                          1
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("g-title", [_vm._v("挂件列表")]),
          _c(
            "div",
            { staticClass: "pendant-list" },
            _vm._l(_vm.pendantList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "pendant",
                  style:
                    item.url == _vm.selectName.url
                      ? "border:1px solid" + _vm.color
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectPendant(item)
                    },
                  },
                },
                [_c("img", { attrs: { src: item.url, alt: "" } })]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("user-cropper-model", {
        ref: "userCropperModel",
        on: { upImg: _vm.upImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }